import React from 'react'
import PropTypes from 'prop-types'
import { StyledTableCell } from 'components/table/table.styles'

const FormattedDate = ({ date, format }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0') // Lembre-se que os meses começam do zero
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
    }

    const formatTime = (dateString) => {
        const date = new Date(dateString)
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')
        return `${hours}:${minutes}`
    }

    return (
        <StyledTableCell>
            {format === 'time' ? formatTime(date) : formatDate(date)}
        </StyledTableCell>
    )
}

FormattedDate.propTypes = {
    date: PropTypes.string.isRequired,
    format: PropTypes.oneOf(['date', 'time'])
}

FormattedDate.defaultProps = {
    format: 'date'
}

export default FormattedDate
