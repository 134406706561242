import * as React from 'react'
import Box from '@mui/material/Box'
import { Can } from 'components/Permission/Can'
import { MODULES } from 'helpers/modules'
import { PERMISSIONS } from 'helpers/permissions'
import { useDeliveryMapByDate } from 'services/deliveryMap/DeliveryMapService'
import BriefTable from '../components/BriefTable'
import { LoadingDeliveryMap } from '../components/LoadingDeliveryMap'

interface DateRange {
  startDate?: Date
  endDate?: Date
}

interface Props {
  date?: Date | DateRange
}

const Brief: React.FC<Props> = ({ date }) => {
  const [page, setPage] = React.useState(1)

  const formattedStartDate =
  (typeof date === 'object' && date !== null && 'startDate' in date)
    ? new Date(date.startDate!).toISOString().split('T')[0]
    : ''

  const formattedEndDate =
  (typeof date === 'object' && date !== null && 'endDate' in date)
    ? new Date(date.endDate!).toISOString().split('T')[0]
    : ''
  console.log('date', date)
  const { data: dataMap, isLoading: isLoadingMap, isError } = useDeliveryMapByDate(
    {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }
  )
  console.log('dataMap', dataMap)

  const balanceZeroCount = !isLoadingMap && dataMap ? dataMap?.balanceZero.length : 0
  const balanceZeroData = !isLoadingMap && dataMap ? dataMap?.balanceZero : []
  const balanceNonZeroCount = !isLoadingMap && dataMap ? dataMap?.balanceNonZero.length : 0
  const balanceNonZeroData = !isLoadingMap && dataMap ? dataMap?.balanceNonZero : []
  const allRecordsCount = !isLoadingMap && dataMap ? dataMap?.allRecords.length : 0
  const allRecordsData = !isLoadingMap && dataMap ? dataMap?.allRecords : []

  return (
<>
  <Can
    module={MODULES.GERENCIAL}
    permissions={[PERMISSIONS.READ]}
  >

    <LoadingDeliveryMap isLoading={isLoadingMap} />

    <Box sx={{ display: 'flex', flexDirection: 'column', visibility: isLoadingMap ? 'hidden' : 'visible' }}
      style={{ marginTop: '10px' }}
    >
      <BriefTable
        dataMap={allRecordsData}
        dataMapZero={balanceZeroData}
        dataMapNonZero={balanceNonZeroData}
        loading={isLoadingMap}
        error={isError}
        page={page}
        setPage={setPage}
        countAll={allRecordsCount}
        countAllZero={balanceZeroCount}
        countAllNonZero={balanceNonZeroCount}
      />
    </Box>
  </Can>
</>
  )
}

export default Brief
