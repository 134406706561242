import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import { ShowErrorDeliveryMap } from './ShowErrorDeliveryMap'
import FormattedDate from 'components/Date/FormattedDate'
import * as XLSX from 'xlsx'
import { Button, Radio, RadioGroup, FormControlLabel, FormControl, Grid, FormLabel, FormGroup, Select, MenuItem, Autocomplete, TextField, InputLabel } from '@mui/material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.common.black,
    height: 30,
    fontSize: 12,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'auto'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'auto'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

interface ClientDetails {
  id: string
  type: string
  name: string
  address: {
    zipcode: string
    publicPlace: string
    number: string
    state: string
    city: string
    district: string
  }
}

interface Item {
  id: string
  deliveryDate: string
  codeLn: string
  itemReference: string
  itemDescription: string
  requestedQuantity: number
  invoicedQuantity: number
  balance: number
  billingDate: string
  taxReference: string
  invoiceLine: string
  requestedWeight: number
  clientDetails: ClientDetails
}

interface BriefTableProps {
  dataMap: Item[] | undefined
  dataMapZero: Item[] | undefined
  dataMapNonZero: Item[] | undefined
  loading: boolean
  error: boolean
  page: number
  setPage: (page: number) => void
  countAll: number
  countAllZero: number
  countAllNonZero: number
}

const transformData = (data: Item[]): Item[] => {
  return data.map((item) => ({
    id: item.id,
    deliveryDate: item.deliveryDate,
    codeLn: item.codeLn,
    itemReference: item.itemReference,
    itemDescription: item.itemDescription,
    requestedQuantity: item.requestedQuantity,
    invoicedQuantity: item.invoicedQuantity,
    balance: item.balance,
    billingDate: item.billingDate,
    taxReference: item.taxReference,
    invoiceLine: item.invoiceLine,
    requestedWeight: item.requestedWeight,
    clientDetails: item.clientDetails
  }))
}

const BriefTable: React.FC<BriefTableProps> = ({
  dataMap = [],
  dataMapZero = [],
  dataMapNonZero = [],
  countAll,
  countAllZero,
  countAllNonZero,
  loading,
  error,
  page,
  setPage
}) => {
  const transformedDataMap = transformData(dataMap)
  const [exportType, setExportType] = useState<'current' | 'all'>('current')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterOption, setFilterOption] = useState('todos')
  const [selectedFT, setSelectedFT] = useState<string | null>(null)
  const [selectedClient, setSelectedClient] = useState<string | null>(null)

  const uniqueFTs = Array.from(new Set(dataMap.map((item) => item.itemReference))).filter(Boolean)
  const uniqueClients = Array.from(new Set(dataMap.map((item) => item.clientDetails.name))).filter(Boolean)

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setRowsPerPage(value === 'all' ? Infinity : parseInt(value, 10))
    setPage(1)
  }

  const exportToExcel = () => {
    const recordsToExport = (exportType === 'all')
    ? filteredDataMap
    : filteredDataMap.slice((page - 1) * rowsPerPage, page * rowsPerPage)

    let formattedDateExp
    const ws = XLSX.utils.json_to_sheet(recordsToExport.map(item => {
      const deliveryDate = new Date(item.deliveryDate)
      const day = String(deliveryDate.getDate()).padStart(2, '0')
      const month = String(deliveryDate.getMonth() + 1).padStart(2, '0')
      const year = deliveryDate.getFullYear()
      const formattedDate = `${day}/${month}/${year}`
      formattedDateExp = `_${day}_${month}_${year}`
      const hours = String(deliveryDate.getHours()).padStart(2, '0')
      const minutes = String(deliveryDate.getMinutes()).padStart(2, '0')
      const formattedTime = `${hours}:${minutes}`

      return {
        DATA_REPROG: formattedDate,
        FT: item.itemReference,
        OV_LN_LINHA_A_FATURAR: item.invoiceLine,
        CLIENTE: `${item.clientDetails.name} - ${item.codeLn}`,
        REFERENCIA: item.itemDescription,
        HORARIO_DE_ENTREGA: formattedTime,
        QUANT_SOLICITADA: item.requestedQuantity,
        QUANT_FATURADA: item.invoicedQuantity,
        SALDO: item.balance
      }
    }))

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, `mapa_de_entrega${formattedDateExp}.xlsx`)
  }

  const filteredDataMap = transformedDataMap
    .filter((item) => {
      if (filterOption === 'zero') return item.balance === 0
      if (filterOption === 'diferenteDeZero') return item.balance !== 0
      return true
    })
    .filter((item) => !selectedFT || item.itemReference === selectedFT)
    .filter((item) => !selectedClient || item.clientDetails.name === selectedClient)

  const displayedData = (rowsPerPage === Infinity)
  ? filteredDataMap
  : filteredDataMap.slice((page - 1) * rowsPerPage, page * rowsPerPage)

  const calculateTotalSums = (dataMap) => {
    let totalRequested = 0
    let totalInvoiced = 0
    let totalBalance = 0

    dataMap.forEach((item) => {
      totalRequested += parseFloat(item?.requestedQuantity) || 0
      totalInvoiced += parseFloat(item?.invoicedQuantity) || 0
      totalBalance += parseFloat(item?.balance) || 0
    })

    return {
      totalRequested,
      totalInvoiced,
      totalBalance
    }
  }

  const totals = calculateTotalSums(filteredDataMap)

  return (
    <>
      <ShowErrorDeliveryMap showError={error} />
      <div style={{ marginBottom: '16px' }}>
        <Grid container alignItems="center">
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup row value={exportType} onChange={(e) => setExportType(e.target.value as 'current' | 'all')}>
                <FormControlLabel value="current" control={<Radio />} label="Página Atual" />
                <FormControlLabel value="all" control={<Radio />} label="Todos" />
              </RadioGroup>
            </FormControl>
            <Button variant="contained" onClick={exportToExcel} style={{ marginLeft: '8px' }}>
              Exportar para Excel
            </Button>
          </Grid>

          <Grid item style={{ marginLeft: '16px', display: 'flex', justifyContent: 'flex-end' }}>
            <Autocomplete
              options={uniqueFTs}
              value={selectedFT}
              onChange={(event, newValue) => setSelectedFT(newValue)}
              renderInput={(params) => <TextField {...params} label="Filtro FT" variant="outlined" size="small" />}
              style={{ width: 200 }}
            />
          </Grid>

          <Grid item style={{ marginLeft: '16px', display: 'flex', justifyContent: 'flex-end' }}>
            <Autocomplete
              options={uniqueClients}
              value={selectedClient}
              onChange={(event, newValue) => setSelectedClient(newValue)}
              renderInput={(params) => <TextField {...params} label="Filtro Cliente" variant="outlined" size="small" />}
              style={{ width: 200 }}
            />
          </Grid>

          <Grid item style={{ marginLeft: '16px', display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl variant="outlined" size="small" style={{ width: 200, marginLeft: '8px' }}>
              <InputLabel>Filtro Saldo</InputLabel>
              <Select
                value={filterOption}
                onChange={handleFilterChange}
                label="Filtro Saldo" // Sets the label text for the Select component
              >
                <MenuItem value="todos">Todos</MenuItem>
                <MenuItem value="zero">0</MenuItem>
                <MenuItem value="diferenteDeZero">!= 0</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item style={{ marginLeft: '16px', display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ marginBottom: '8px', fontWeight: 'bold' }}>Totais</FormLabel>
              <FormGroup row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <span style={{ marginRight: '16px', fontSize: '16px', color: '#333' }}>Solicitado:</span>  <span style={{ marginRight: '16px', fontSize: '16px', color: '#333', fontWeight: 'bold' }}>{totals?.totalRequested}</span>
                <span style={{ marginRight: '16px', fontSize: '16px', color: '#333' }}>Faturado: </span> <span style={{ marginRight: '16px', fontSize: '16px', color: '#333', fontWeight: 'bold' }}>{totals?.totalInvoiced}</span>
                <span style={{ fontSize: '16px', color: '#333' }}>Saldo: </span> <span style={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}>{totals?.totalBalance}</span>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: '12%' }}>DATA REPROG.</StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>FT</StyledTableCell>
              <StyledTableCell style={{ width: '18%' }}>OV LN LINHA A FATURAR</StyledTableCell>
              <StyledTableCell style={{ width: '25%' }}>CLIENTE</StyledTableCell>
              <StyledTableCell style={{ width: '20%' }}>REFERÊNCIA</StyledTableCell>
              <StyledTableCell style={{ width: '12%' }}>HORÁRIO DE ENTREGA</StyledTableCell>
              <StyledTableCell style={{ width: '12%' }}>QTD SOLICITADA</StyledTableCell>
              <StyledTableCell style={{ width: '12%' }}>QTD FATURADA</StyledTableCell>
              <StyledTableCell style={{ width: '12%' }}>PESO</StyledTableCell>
              <StyledTableCell style={{ width: '10%' }}>
                SALDO
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((item) => (
              <StyledTableRow key={item.id}>
                <FormattedDate date={item.deliveryDate} format="date" />
                <StyledTableCell>{item.itemReference ? item.itemReference : ' - '}</StyledTableCell>
                <StyledTableCell>{item.invoiceLine}</StyledTableCell>
                <StyledTableCell>{item.clientDetails.name} - {item.codeLn}</StyledTableCell>
                <StyledTableCell>{item.itemDescription}</StyledTableCell>
                <FormattedDate date={item.deliveryDate} format="time" />
                <StyledTableCell>{item.requestedQuantity}</StyledTableCell>
                <StyledTableCell>{item.invoicedQuantity}</StyledTableCell>
                <StyledTableCell>{item.requestedWeight}</StyledTableCell>
                <StyledTableCell style={{
                  color: item.balance > 0 ? 'red' : item.balance < 0 ? 'blue' : 'green'
                }}>
                  {item.balance < 0 ? -item.balance : item.balance}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={ (filterOption === 'todos') ? countAll : (filterOption === 'zero') ? countAllZero : countAllNonZero }
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, { value: 1000, label: 'All' }]}
        />
      </TableContainer>
    </>
  )
}

export default BriefTable
