import { httpClient } from 'config/httpClient'
import { useQuery } from 'react-query'

const getDeliveryMapToday = async ({ page, offset, limit, search, startDate, endDate }) => {
  const { data } = await httpClient.get('/ftsByDeliveryMap/delivery-map-paginated', {
    params: {
      page,
      offset,
      limit,
      search,
      startDate,
      endDate
    }
  })

  return {
    response: data.response,
    totalCount: data.totalCount
  }
}

export const useDeliveryMapToday = (params) => {
  return useQuery(
    ['delivery-map-paginated', params],
    async () => await getDeliveryMapToday(params),
    {
      refetchOnWindowFocus: false
    }
  )
}

const getDeliveryMapByDate = async ({ startDate, endDate }) => {
  const response = await httpClient.get('/ftsByDeliveryMap/delivery-map', {
    params: {
      startDate,
      endDate
    }
  })

  console.log('Response:', response.data[0])

  return response.data[0]
}

export const useDeliveryMapByDate = (params) => {
  return useQuery(
    ['delivery-map', params],
    async () => await getDeliveryMapByDate(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params.startDate && !!params.endDate
    }
  )
}
